.mobile_table select {
  border: 0;
  background-color: white;
  appearance: none;
  border-bottom: #c0c0c0 solid 1px;
}

.mobile_table {
  padding: 5px;
  border-radius: 3px;
  border: 3px solid #727272;
  margin: 10px 0 10px 0;
}

.mobile_table_row {
  display: flex;
  margin: 0 0 15px 0;
}

.mobile_table_header {
  position: relative;
  font-size: 15px;
  background-color: #f0f0f0;
  text-align: center;
  padding: 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 3px solid #727272;
  border-bottom: none;
  margin: -8px -8px 10px -8px;
}

.mobile_table_label {
  width: 150px;
  margin: 0 5px 0 0;
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-bottom: #c0c0c0 solid 1px;
  font-size: 15px;
}

.mobile_table_el {
  font-size: 15px;
  /* margin: 0 5px 0 0; */
  border: 0;
  border-bottom: #c0c0c0 solid 1px;
  word-wrap: break-word;
  width: calc(100% - 155px);
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
}

.mobile_table_el--full_row {
  width: 100%;
}

.mobile_table_el--part {
  width: calc((100% - 165px) / 3);
  margin: 0 5px 0 0;
}

.mobile_table_el--part:last-child {
  margin: 0;
}

.mobile_table_header_button {
  position: absolute;
  right: 5px;
  top: 50%;
  font-size: 10px;
  padding: 4px 8px;
  transform: translate(0, -50%);
}

.mobile_table_cargo_button {
  margin: 0 5px 0 0;
  width: calc((100% - 165px) / 3);
  text-align: center;
}
.mobile_table_cargo_button:last-child {
  margin: 0;
}
.mobile_table_cargo_input {
  margin: 0;
  width: 50px;
  text-align: center;
}
