body {
  height: auto;
}

.mobile_container {
  position: relative;
  padding: 0 5px 0 5px;
}

.mobile_heading {
  text-align: center;
  background-color: #f0f0f0;
  padding: 10px 0 10px 0;
  margin: 0 0 10px 0;
}

.mobile_select {
  margin: 10px 0;
  height: 30px;
  width: 100%;
}

.foto {
  width: 100%;
}

.file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.video_container {
  position: relative;
}

.video_button {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  border: 0;
  width: 50px;
  height: 50px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.mobile_button_container {
  margin: 0 0 10px 0;
  text-align: center;
}

.camera_button {
  display: block;
  margin: 10px auto;
  padding: 10px;
  width: 250px;
  height: 40px;
  background-color: #b3b3b3;
  text-align: center;
}

.check {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 0;
  right: 5px;
}

.calc_volume_weight_container {
  border-top: #f0f0f0 2px solid;
}

.selected {
  opacity: 0.6;
}

.scanner_container {
  position: relative;
}

.scanner_close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 32px;
  height: 32px;
  z-index: 50;
}
.scanner_close:hover {
  opacity: 1;
}

.scanner_close:before,
.scanner_close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 5px;
  background-color: rgb(0, 0, 0);
}

.scanner_close:before {
  transform: rotate(45deg);
}
.scanner_close:after {
  transform: rotate(-45deg);
}

.menu_icon {
  position: absolute;
  left: 22px;
  top: 8px;
  font-size: 24px;
  color: #2443b5;
}

.action_icon {
  margin: 0 4px;
  font-size: 18px;
  color: #2443b5;
}
