* {
  outline: 0 !important;
}

body {
  height: 100%;
  font-family: "Open Sans", sans-serif;
}

i {
  font-size: 16px;
  /* margin: 0 8px; */
}

.menu-icon {
  margin: 0 8px;
}

.margin-right-8 {
  margin-right: 8px;
}

.red {
  color: red;
}

select {
  padding: 6px;
  margin: 0 4px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
}

.input-checkbox {
  width: 18px;
  height: 18px;
}

button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: #d0d0d0;
}

.table-wrapper {
  height: calc(100% - 32px);
  overflow-x: auto;
}

table {
  border-collapse: separate !important;
  border-spacing: 0;
  margin: 10px 0;
  width: 100%;
}

.bordered {
  border: solid #ccc 1px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.bordered tr:hover {
  background: #ececec;
}
.bordered td {
  font-size: 12px;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding: 6px;
  text-align: left;
}

.bordered th {
  font-size: 12px;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding: 8px;
  text-align: left;
  background-color: #f8f8f8;
  border-top: none;
}
.bordered td:first-child,
.bordered th:first-child {
  border-left: none;
}
.bordered th:first-child {
  -moz-border-radius: 4px 0 0 0;
  -webkit-border-radius: 4px 0 0 0;
  border-radius: 4px 0 0 0;
}
.bordered th:last-child {
  -moz-border-radius: 0 6px 0 0;
  -webkit-border-radius: 0 6px 0 0;
  border-radius: 0 6px 0 0;
}
.bordered th:only-child {
  -moz-border-radius: 4px 6px 0 0;
  -webkit-border-radius: 4px 6px 0 0;
  border-radius: 4px 6px 0 0;
}
.bordered tr:last-child td:first-child {
  -moz-border-radius: 0 0 0 6px;
  -webkit-border-radius: 0 0 0 6px;
  border-radius: 0 0 0 6px;
}
.bordered tr:last-child td:last-child {
  -moz-border-radius: 0 0 6px 0;
  -webkit-border-radius: 0 0 6px 0;
  border-radius: 0 0 6px 0;
}

td {
  padding: 4px;
}

th {
  padding: 8px;
  font-weight: 600;
}

tr.active {
  background-color: #00000020;
}

:root {
  --logo-size: 80px;
  --logo-half-size: 40px;
}
.preloader {
  display: flex;
  width: 100%;
  height: 100vh;
  background: #fff;
  position: relative;
  transition: 0.5s;
}

.notmargin {
  margin: 0;
}
.preloader__image_animate {
  background-image: url("./wait.svg");
  background-size: var(--logo-size) var(--logo-size);
  width: var(--logo-size);
  height: var(--logo-size);
  position: absolute;
  top: calc(50% - var(--logo-half-size));
  left: calc(50% - var(--logo-half-size));
  transition: 1s linear;
  animation: up-down 1s infinite;
}
@keyframes up-down {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.send_pod {
  height: 25px;
  border-radius: 4px;
  background-color: rgb(255, 205, 162);
  margin: 5px;
}

.disp_Number {
  font-size: 16px;
  color: #303030;
  padding: 5px;
  border-bottom: #303030 solid 1px;
}

.leftmenuel {
  float: center;
  color: #2443b5;
  background-color: #f0f0f0;
  cursor: pointer;
  text-align: left;
  padding: 10px 10px;
  text-decoration: none;
  font-size: 12px;
  border-radius: 4px;
  margin: 10px 5px;
  cursor: pointer;
}

.leftmenubutton {
  margin: 10px 5px;
  color: #2443b5;
}

.leftmenubuttonicon {
  color: #2443b5;
  margin-right: -3.5px;
}

.leftmenuel:hover {
  background-color: #e87b1a;
  color: #ffffff;
}

.leftmenuel:active {
  background-color: #c66814;
}

.leftmenu {
  float: center;

  background-color: #ffffff;
  border: #d4d4d5 1px solid;

  padding: 5px 5px;
  text-decoration: none;
  font-size: 14px;
  border-radius: 4px;
  margin: 10px 5px;
}

.content_window {
  float: center;
  background-color: #ffffff;
  border: #d4d4d5 1px solid;
  padding: 5px 5px;
  text-decoration: none;
  border-radius: 4px;
  margin: 10px 5px;
}

.content_window_home {
  float: center;
  background-color: #ffffff;

  text-decoration: none;

  margin: 10px 5px;
}

.manifest_complited {
  font-size: 14px;
  background-color: #f0f0f0;
  text-align: center;
  padding: 10px;
  border-radius: 4px;

  border: #c0c0c0 1px solid;

  margin: 5px;
  margin-bottom: 0px;
}

.disp_address_data_header {
  font-size: 14px;
  background-color: #f0f0f0;
  text-align: center;
  padding: 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: #c0c0c0 1px solid;
  border-bottom: none;
  margin: 5px;
  margin-bottom: 0px;
}
.disp_data_label {
  margin: 3px;
  font-size: 13px;
  border-bottom: #c0c0c0 solid 1px;
}

.disp_data_radio {
  padding: 8px 0 0 0;
}
.disp_data_radio input {
  cursor: pointer;
}

.disp_data_input {
  margin: 3px;
  font-size: 13px;
  /* border-bottom: #c0c0c0 solid 1px; */
}

.disp_cargo_table {
  margin: 5px;
}

.disp_cargo_table_header {
  font-size: 14px;
  margin: 0 3px;
}

.pod_header {
  width: 400px;
  font-size: 14px;
  background-color: #f0f0f0;
  text-align: center;
  padding: 10px 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: #c0c0c0 1px solid;
  border-bottom: none;
  margin: 5px;
  margin-bottom: 0px;
}

.pod_input {
  width: 140px;
  border: none;
  border-bottom: #c0c0c0 1px solid;
  cursor: pointer;
}
.pod_input:hover {
  background-color: #f0f0f0;
}

.disp_data_el {
  font-size: 11px;
  margin: 3px;
  border-bottom: #c0c0c0 solid 1px;
}

.disp_data_select {
  font-size: 13px;
  margin: 3px;
}

.disp_address_data {
  display: grid;
  grid-template-rows: 40px 1fr;
  grid-template-columns: 1fr 1fr;
  margin: 5px;
}

.search_storage {
  display: grid;
  grid-template-columns: 150px 200px;
  margin: 5px;
}

.disp_customer_data {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 200px 1fr;
  margin: 5px;
}

.pod_data {
  width: 400px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 200px 150px;
  margin: 5px;
  padding: 5px 0;
  margin-top: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: #c0c0c0 1px solid;
  border-top: none;
}

.disp_cargo_data {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 250px 180px;
  margin: 5px;
}

.disp_cargo_data_fragile {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 250px 180px;
  margin: 5px;
  align-items: center;
}

.grid-container {
  display: grid;
  grid-template-rows: 60px 1fr 60px;
  height: 100vh;
  margin: 0;
}

.grid-container--login {
  display: grid;
  grid-template-rows: 1fr 60px;
  height: 100vh;
  margin: 0;
}

.disp_address_data_el {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 150px 1fr;
  margin: 5px;
  padding: 5px 0;
  margin-top: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: #c0c0c0 1px solid;
  border-top: none;
}

.header_logo {
  float: left;
  height: 40px;
  margin: 10px 30px;
  cursor: pointer;
}

.home {
  display: grid;
  grid-template-rows: 100px 1fr;
  grid-template-columns: 50% 50%;
  grid-template-areas: " logo logo " " express trans ";
  margin: 0;
}

.logged_main {
  display: grid;
  grid-template-columns: 250px 1fr;
  margin: 0;
}

.logged_main_compact {
  display: grid;
  grid-template-columns: 85px 1fr;
  margin: 0;
}

.not_logged_main {
  display: grid;
  grid-template-columns: 1fr;
  margin: 0;
}

#logo {
  grid-area: logo;
}

.logo {
  margin: 40px;
  width: 250px;
}

.logo-block {
  text-align: center;
}

.home-block {
  text-align: center;

  border: #e0e0e0 solid 1px;
  border-radius: 10px;
  margin: 20px;
}

.home-block-content {
  margin: 10px;
}
.topnav {
  display: grid;
  grid-template-columns: 250px 1fr 450px;
  background-color: #ffffff;
  overflow: hidden;
  border-bottom: 2px solid #e87b1a;
}

.topnavusername {
  float: left;
  color: #303030;
  text-align: center;
  padding: 10px 10px;
  text-decoration: none;
  font-size: 14px;
  margin-top: 10px;
  text-align: right;
}

.logout {
  float: left;
  color: #303030;
  background-color: #f0f0f0;
  border: #e8e8e8 1px solid;
  text-align: center;
  margin: 10px 5px;
  padding: 10px 10px;
  text-decoration: none;
  font-size: 14px;
  border-radius: 4px;
}

.logout:hover {
  background-color: #ddd;
  color: black;
  cursor: pointer;
}

.topnavel {
  float: left;
  color: #2443b5;
  background-color: #f0f0f0;
  border: #e8e8e8 1px solid;
  text-align: center;
  padding: 10px 10px;
  text-decoration: none;
  font-size: 14px;
  border-radius: 4px;
  margin: 10px 5px;
}

.topnaname {
  float: left;
  color: #2443b5;
  margin: 4px;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnavel:hover {
  background-color: #e87b1a;
  color: #ffffff;
}

.topnav .login-container {
  float: right;
}

.topnav input {
  padding: 10px;
  margin: 10px 5px;
  border-radius: 4px;
  width: 150px; /* adjust as needed (as long as it doesn't break the topnav) */
}

.map {
  margin: auto;
  width: 400px;
}

.footerleftel {
  margin: 15px;
  font-size: 10px;
}

.footerrightel {
  text-align: right;
  margin: 15px;
  font-size: 10px;
}

.footercenterel {
  text-align: center;
  margin: 15px;
  font-size: 10px;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #000;
  color: #fff;
  overflow: hidden;
  border-top: 2px solid #e87b1a;
}

.mobile_version {
  width: 150px;
  margin-left: 20px;
}

.small_table_data {
  font-size: 10px;
}

.control_panel {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.my_disp_control_panel--small {
  grid-template-columns: 130px 150px 150px;
}

.my_disp_control_panel--s {
  grid-template-columns: 150px 1fr;
}

.login-container {
  display: grid;
  grid-template-columns: 1fr 100px;
}

.login-pass-input {
  text-align: right;
}

.mainmenu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.main_menu_button {
  color: #303030;
  background-color: #f0f0f0;
  border: #e8e8e8 1px solid;
  text-align: center;
  margin: 10px 5px;
  padding: 10px 10px;
  text-decoration: none;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.main_menu_button:hover {
  background-color: #e87b1a;
  color: #ffffff;
}

.setting_template_data {
  display: grid;

  grid-template-columns: 400px 30px 100px;
  margin: 5px;
}

.setting_general_data {
  display: grid;

  grid-template-columns: 300px 230px;
  margin: 5px 5px 0 5px;
}

.setting_general_data_template {
  display: grid;
  grid-template-columns: 300px 230px 100px;
  margin: 0 5px 5px 5px;
}

.page_header {
  margin: 0 10px;
}

.setting_data_el {
  font-size: 11px;
  margin: 3px;
  border-bottom: #c0c0c0 solid 1px;
  min-height: 28px;
}

.setting_template_button {
  padding: 5px 0 0 0;
}

.old {
  margin: auto;
  font-weight: bold;
  background-color: #e87b1a;
  padding: 10px 30px;
  border: solid 2px #e87b1a;
}

.old a {
  color: white;
}

.old:hover a {
  color: #e87b1a;
}

.old:hover {
  background-color: #fff;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .send_pod {
    width: 200px;
    height: 30px;
    background-color: #cacbcd;
    color: rgba(0, 0, 0, 0.8);
    border: none;
    border-radius: 4px;
  }

  .disp_data_el {
    font-size: 16px;
  }

  .disp_Number {
    font-size: 16px;
    color: #303030;
    padding: 5px;
    border-bottom: none;
  }

  .disp_customer_data {
    grid-template-rows: auto;
  }

  .disp_address_data_el {
    padding: 5px;
    grid-template-rows: auto;
  }
}
