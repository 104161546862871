.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Прозрачный черный цвет */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.modal {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  z-index: 3;
}

.modal-header {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-button {
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  overflow-x: auto;
  margin-bottom: 20px;
  height: 100%;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
