.mobile_login_container {
  margin: 100px auto 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.mobile_login_item {
  color: #303030;

  border: #e8e8e8 1px solid;
  text-align: center;
  margin: 10px 0 0 0;
  padding: 10px 10px;
  text-decoration: none;
  font-size: 14px;
  border-radius: 3px;
}

.mobile_logout {
  background-color: #f0f0f0;
  cursor: pointer;
}

.login_logo {
  height: 60px;
  margin: 0 0 50px 0;
}
