.m_disp_tempalate_item {
  text-align: center;
  font-size: 20px;
  border: 3px solid #727272;
  padding: 12px;
  margin: 0 0 10px 0;
  width: 100%;
  background-color: white;
}

.m_disp_tempalate_item--defult {
  background-color: #e9e9e9;
}
