.storage_reciept_container {
  margin: 10px;
  font-size: 16px;
}

.storage_reciept_item {
  margin: 20px 0 0 0;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #f0f0f0;
}

.storage_reciept_input {
  margin: 20px 0 0 0;
  height: 30px;
  width: 205px;
}

.storage_reciept_button {
  height: 30px;
  margin: 10px 0 20px 10px;
  background-color: #cacbcd;
  box-shadow:
    0 0 0 1px transparent inset,
    0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  color: rgba(0, 0, 0, 0.8);
  border: none;
  border-radius: 3px;
}

.storage_reciept_button_m {
  height: 30px;
  margin: 0 0 0 10px;
  background-color: #cacbcd;
  box-shadow:
    0 0 0 60px rgb(0 0 0 / 0%) inset,
    0.1em 0.1em 0.2em rgb(122 122 122);
  color: rgba(0, 0, 0, 0.8);
  border: none;
  border-radius: 3px;
}

.storage_reciept_button_m--fw {
  margin: 15px 0 10px 0;
  width: 100%;
}

.storage_reciept_list_item {
  margin: 10px 0 0 0;
  padding: 5px;
  border: solid #000 2px;
  border-radius: 5px;
}

.storage_reciept_list_row {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
}

.storage_reciept_list_label {
  border-bottom: solid #c0c0c0 1px;
  width: 100px;
}

.storage_reciept_list_value {
  border-bottom: solid #c0c0c0 1px;
  width: calc(100% - 105px);
}

.storage_reciept_button_delete {
  padding: 8px 0px;
  margin: 10px 0;
  border: 0;
  width: 100%;
  box-shadow:
    0 0 0 60px rgb(0 0 0 / 0%) inset,
    0.1em 0.1em 0.2em rgb(122 122 122);
  background-color: rgb(255, 187, 0);
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin: 0 0 0 10px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
