.login__container {
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  align-content: center;
  gap: 40px;
  background-color: #fff;
}

.login__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.login__logo {
  margin: 0 auto 0 auto;
  height: 100px;
}

.login__button {
  width: 100%;
  max-width: 400px;
  padding: 10px 10px;
  border: #cfcfcf 1px solid;
  border-radius: 3px;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
}

.login__button:hover {
  background-color: #ddd;
}

.login__button:after {
  background-color: #c3c3c3;
}

.login__button_back {
  width: 100%;
  max-width: 400px;
  padding: 10px 10px;
  border: #cfcfcf 1px solid;
  border-radius: 3px;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
}

.login__button_back:hover {
  background-color: #ddd;
}

.login__label {
  font-size: 24px;
  color: #000;
}

.login__label:hover {
  color: #000;
}

.login__input {
  width: 100%;
  max-width: 398px;
  margin: 0 0 12px 0;
  padding: 10px 0;
  border: #e8e8e8 1px solid;
  border-radius: 3px;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
}
