ul {
  margin: 0;
  padding: 0;
  text-align: center;
}

.mobile_menu {
  padding: 30px 0 30px 0;
}

.mobile_menu_item {
  list-style-type: none;
  margin: 18px 0 0 0;
}

.mobile_menu_item:first-child {
  margin-top: 0px;
}

.mobile_menu_item:last-child {
  margin-bottom: 0px;
}

.mobile_menu_button {
  width: 100%;
  cursor: pointer;
  font-size: 18px;
  color: #2443b5;
  background-color: #f0f0f0;
  padding: 15px 15px;
  text-decoration: none;
  border: 0;
  border-radius: 3px;
}
