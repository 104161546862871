.templates_general_data {
  display: grid;

  grid-template-columns: 200px 230px;
  margin: 5px;
}

.templates_button_panel {
  margin: 5px;
}
