.disp_map {
  height: calc(100vh - 190px);
  width: clac(100% - 250px);
}

.disp_map_button {
  margin-bottom: 5px;
}

.disp_map_button--flex {
  display: flex;
  align-items: center;
}

.disp_map_loader {
  border: 6px solid #e0e1e2;
  border-top: 6px solid #2444b5;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.disp_map_window {
  display: grid;
  grid-template-columns: 250px 1fr;
}

.disp_map_left_menu {
  display: flex;
  flex-direction: column;
}

.disp_map_panel {
  height: 35px;
  display: grid;
  grid-template-columns: 4fr 3fr 8fr 4fr 2fr 2fr;
}

#rnk_div {
  display: grid;
  grid-template-columns: 80px 40px;
}
.disp_map_panel_input_element {
  display: grid;
  grid-template-columns: 80px 1fr;
}
.disp_map_panel_element {
  margin: 0 10px;
}

.blink {
  animation: blinkingBackground 0.2s infinite;
}

.map_marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;

  border: 1px solid;

  border-radius: 30%;
  /* user-select: none; */
  transform: translate(-50%, -50%);
}

.point {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border: 1px solid;
  border-radius: 50%;
}

.disp_map_error {
  margin: auto 0 0 0;
}

.disp_map_disp_num {
  margin: 5px 0;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #4183c4 !important;
}

@keyframes blinkingBackground {
  0% {
    background-color: #fff;
    border-color: #fff;
  }
}

#disp_map_courier_input {
  width: 250px;
  margin-right: 3px;
  border-radius: 2px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  border: 1px solid #ddd;
  z-index: 1;
  padding: 3px;
  border-radius: 3px;
}

.dropdown-content p {
  margin: 0;
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content p:hover {
  background-color: #f1f1f1;
}

.overflow_y {
  overflow-y: auto;
  max-height: calc(100vh - 155px);
  margin-right: 5px;
}

.dropdown-customer {
  display: block;

  min-width: 150px;

  z-index: 1;
  padding: 3px;
}

.dropdown-customer p {
  margin: 0;
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-customer p:hover {
  background-color: #f1f1f1;
}

.left_menu_filter {
  display: grid;
  grid-template-columns: 1fr 30px;
}

.dropdown-customer button {
  margin-left: 5px;
}
