.create_disp_data_input {
  border: none;
  width: 100%;
  font-size: 11px;
  padding: 0;
}

.create_disp_data_dropdown {
  min-height: 10px;
}

.disp_cargo_info {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 250px 1fr;
  margin: 5px;
}

.create_disp_td_input {
  border: none;

  width: 70px;
  font-size: 11px;
}

.ButtonTD {
  border: none;
  padding: 2px;
}

.IconButton {
  height: 32px;
  width: 40px;
  border-radius: 2px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.disp_cargo_table_data {
  margin-bottom: 7px;
}

.create_disp_address_data_header {
  font-size: 14px;
  background-color: #f0f0f0;
  text-align: center;
  padding: 6px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: #c0c0c0 1px solid;
  border-bottom: none;
  margin: 5px;
  margin-bottom: 0px;
  display: grid;
  grid-template-columns: 100px 1fr 100px;
}

.create_disp_template_button {
  /* border: solid; */
  border-color: #888;
  background-color: #fff;
  border-radius: 5px;
  font-size: 10px;
  padding: 4px;
  cursor: pointer;
}

.create_disp_template_button_container {
  text-align: right;
}

.create_disp_template_list_tr {
  font-size: 10px;
}

.create_disp_template_list_tr:hover {
  background-color: #f0f0f0;
}

.create_disp_template_list_th {
  font-size: 12px;
}

.DispDate {
  border: none;
  cursor: pointer;
}
.DispDate:hover {
  background-color: #f0f0f0;
}

@media (max-width: 1000px) {
  .create_disp_data_input {
    font-size: 15px;
  }
}

.loader_custom {
  border: 4px solid #acacac;
  border-top: 4px solid #2444b5;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
