.disp_print {
  padding: 20px;
  margin: 0;
  page-break-inside: avoid !important;
}

.disp_print_header {
  display: grid;
  grid-template-columns: 250px 1fr 150px;
  height: 130px !important;
  font-size: 26px;
  text-align: center;
}

.logo_container {
  padding: 0 0;
}

.city_container {
  padding: 20px 0 0 0;
}

.qrcode_container {
  padding: 0 0;
  grid-column: 3;
  grid-row: 1 / span 2;
}

.barcode_container {
  padding: 30px 0;
}

.disp_print_body {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.disp_print_address_data {
  display: grid;
  grid-template-rows: 30px 1fr;
  grid-template-columns: 1fr 1fr;
  margin: 5px;
}

.disp_print_address_data_header {
  font-size: 12px;
  background-color: #f0f0f0;
  text-align: center;
  padding: 2px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: #c0c0c0 1px solid;
  border-bottom: none;
  margin: 0 2px;
}

.disp_print_address_data_el {
  display: grid;
  /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr; */
  grid-template-columns: 100px 1fr;
  margin: 2px;
  padding: 2px 0;
  margin-top: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: #c0c0c0 1px solid;
  border-top: none;
}

.disp_print_data_label {
  margin: 0 3px;
  font-size: 10px;
  border-bottom: #c0c0c0 solid 1px;
}

.disp_print_data_label--payment {
  font-size: 15px;
  font-weight: bold;
  text-decoration: underline;
}

.disp_print_data_el {
  font-size: 10px;
  margin: 0 3px;
  border-bottom: #c0c0c0 solid 1px;
}

.disp_print_data {
  margin: 0 5px;
  display: grid;
  grid-template-columns: 240px 1fr;
}

.disp_print_customer_data {
  margin: 2px;

  padding: 2px 0;
  margin-top: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: #c0c0c0 1px solid;
  border-top: none;
  display: grid;
  grid-template-columns: 120px 1fr;
}

.disp_print_cargo_data {
  margin-right: 2px;
}
.disp_print_cargo_table {
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
}

.disp_print_cargo_th {
  border-right: 1px solid #c0c0c0;
  border-top: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  font-size: 9px;
}

.disp_print_cargo_td {
  border-right: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  font-size: 9px;
  text-align: center;
}

.top_left_border_radius {
  border-top-left-radius: 3px;
}

.top_right_border_radius {
  border-top-right-radius: 3px;
}

.disp_print_cargo_th:first-child,
.disp_print_cargo_td:first-child {
  border-left: 1px solid #c0c0c0;
}

/* bottom-left border-radius */
.disp_print_cargo_table tr:last-child td:first-child {
  border-bottom-left-radius: 3px;
}

/* bottom-right border-radius */
.disp_print_cargo_table tr:last-child td:last-child {
  border-bottom-right-radius: 3px;
}

.disp_print_cargo_footer {
  display: grid;
  grid-template-columns: 100px 40px 30px 100px 40px 30px 100px 40px;
  margin: 10px;
}

.disp_print_info_footer {
  display: grid;
  grid-template-columns: 160px 160px;
  margin: 10px;
}

.center {
  text-align: center;
}

.disp_print_pod_table {
  margin: 0;
  border: 1px solid #c0c0c0;
  border-collapse: collapse;
}

.disp_print_pod_td {
  border: 1px solid #c0c0c0;
  font-size: 7px;
  height: 20px;
  text-align: center;
  padding-top: 15px;
  color: #444;
}

.disp_print_list {
  height: 290mm;
  width: 200mm;
  /* display: grid; */
  /* grid-template-rows:  1fr 1fr; */
  page-break-after: always !important;
}

.disp_print_pod_container {
  margin-left: 7px;
  margin-right: 7px;
  margin-top: 0;
  margin-bottom: 0;
}

.sticker {
  height: 200px;
  padding: 3px;
  break-after: always !important;
  page-break-after: always !important;
  page-break-inside: avoid !important;
}

.sticker_info {
  font-size: 12px;
  height: 12px;
}

.sticker_number {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
}

.sticker_site {
  text-align: center;
  font-size: 9pt;
}

.sticker_code_wrapper {
  display: flex;
  justify-content: stretch;
}

.sticker_cargo_wrapper {
  width: 126px;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.sticker_cargo {
  text-align: center;
  font-size: 22pt;
  height: 30px;
}

.sticker_rec_city {
  text-align: center;
  font-size: 20px;
  height: 24px;
  padding: 1px;
}

.page_break {
  page-break-after: always !important;
}

.sticker_barcode {
  text-align: center;
  height: 100px;
  width: 100px;
  padding: auto;
  margin: 0;
}

.sticker_list {
  width: 60mm;
}

.sticker_component {
  display: none;

  @media print {
    display: block;
  }
}

.search_input {
  width: 600px;
  margin-right: 10px;
}

.search_box {
  margin: 5px;
}

.send_date {
  margin-left: 70px;
  margin-top: -10px;
  padding: 0;
  position: absolute;

  font-size: 8pt;
}

.disp_skan {
  width: 100%;
}

.loader_container {
  height: 150px;
}

.disp_skan_button {
  border: 0;
  background-color: white;
  color: #0000c2;
  font-weight: bold;
  cursor: pointer;
}

.disp_skan_button:hover {
  text-decoration: underline;
}

.disp_actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 12px;
}
