h2 {
  margin: 0;
  padding: 0;
}

.check_print_qr {
  margin: 30px;
  /* font-family: courier; */
}

.check_print_row {
  display: flex;
  justify-content: space-between;
  /* font-family: courier; */
}

.check_print_row--center {
  justify-content: center;
}

.check_print_row--right {
  justify-content: flex-end;
}

.qr {
  display: block;
  margin: 0 auto;
}

.check_print_line_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.check_print_line {
  border-bottom: 1px dashed black;
  width: 100%;
  height: 21px;
}
