thead.my_disp_data_input {
  border: none;
  width: 50%;
  font-size: 11px;
  padding: 2px;
  position: relative;
  opacity: 100%;
}

.position_revative {
  position: relative;
}

.my_disp_data_el {
  font-size: 11px;
  margin: 3px;
  border-bottom: #616161 solid 1px;
}

.my_disp_div_filter {
  width: 100%;
}

.my_disp_div_filter_input {
  border: 1px solid #00000088;
  border-radius: 2px;
  margin: 0 3px 5px 0;
  width: calc(100% - 27px);
  padding: 3px;
  font-size: 8pt;
}

.my_disp_dropdown_head {
  width: 100%;
  padding: 3px;
}

.margin_right {
  margin-right: 5px;
}

.my_disp_button {
  font-size: 8pt;
  cursor: pointer;
}

.my_disp_filter_selected_text {
  font-size: 7pt;
}

.on_bottom {
  bottom: 100px;
}

.my_disp_select {
  margin: 0 10px 0 0;
}

.my_disp_table {
  height: calc(100vh - 193px);
  overflow-y: scroll;
}

.my_disp_button_more {
  position: relative;
  cursor: pointer;
  margin-right: 5px;
}
.my_disp_button_more-left {
  background-color: transparent;
  width: 10px;
  height: 3px;
  display: block;
  transform: rotate(35deg);
  float: right;
  border-radius: 2px;
}
.my_disp_button_more-left:after {
  content: "";
  background-color: #2443b5;
  width: 10px;
  height: 3px;
  display: block;
  float: right;
  border-radius: 6px 10px 10px 6px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}

.my_disp_button_more-right {
  position: absolute;
  background-color: transparent;
  left: 7px;
  width: 10px;
  height: 3px;
  display: block;
  transform: rotate(-35deg);
  float: right;
  border-radius: 2px;
}
.my_disp_button_more-right:after {
  content: "";
  background-color: #2443b5;
  width: 10px;
  height: 3px;
  display: block;
  float: right;
  border-radius: 10px 6px 6px 10px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}
.open .my_disp_button_more-left:after {
  transform-origin: center center;
  transform: rotate(-70deg);
}
.open .my_disp_button_more-right:after {
  transform-origin: center center;
  transform: rotate(70deg);
}
