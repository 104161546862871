@font-face {
  font-family: "ek-icons";
  src: url("fonts/ek-icons.eot?y2wip6");
  src:
    url("fonts/ek-icons.eot?y2wip6#iefix") format("embedded-opentype"),
    url("fonts/ek-icons.ttf?y2wip6") format("truetype"),
    url("fonts/ek-icons.woff?y2wip6") format("woff"),
    url("fonts/ek-icons.svg?y2wip6#ek-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ek-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ek-logo:before {
  content: "\e901";
}
.ek-fragile:before {
  content: "\e902";
}
.ek-thermometer-icon:before {
  content: "\e904";
}
.ek-home:before {
  content: "\e900";
}
.ek-office:before {
  content: "\e903";
}
.ek-pencil:before {
  content: "\e905";
}
.ek-camera:before {
  content: "\e90f";
}
.ek-music:before {
  content: "\e911";
}
.ek-file-text:before {
  content: "\e922";
}
.ek-file-empty:before {
  content: "\e924";
}
.ek-files-empty:before {
  content: "\e925";
}
.ek-file-text2:before {
  content: "\e926";
}
.ek-file-picture:before {
  content: "\e927";
}
.ek-file-music:before {
  content: "\e928";
}
.ek-copy:before {
  content: "\e92c";
}
.ek-paste:before {
  content: "\e92d";
}
.ek-stack:before {
  content: "\e92e";
}
.ek-barcode:before {
  content: "\e937";
}
.ek-qrcode:before {
  content: "\e938";
}
.ek-coin:before {
  content: "\e93b";
}
.ek-credit-card:before {
  content: "\e93f";
}
.ek-calculator:before {
  content: "\e940";
}
.ek-address-book:before {
  content: "\e944";
}
.ek-location:before {
  content: "\e948";
}
.ek-compass:before {
  content: "\e949";
}
.ek-map:before {
  content: "\e94c";
}
.ek-clock:before {
  content: "\e94e";
}
.ek-calendar:before {
  content: "\e953";
}
.ek-printer:before {
  content: "\e954";
}
.ek-display:before {
  content: "\e956";
}
.ek-mobile:before {
  content: "\e958";
}
.ek-drawer:before {
  content: "\e95c";
}
.ek-drawer2:before {
  content: "\e95d";
}
.ek-box-add:before {
  content: "\e95e";
}
.ek-download:before {
  content: "\e960";
}
.ek-upload:before {
  content: "\e961";
}
.ek-save:before {
  content: "\e962";
}
.ek-database:before {
  content: "\e964";
}
.ek-undo:before {
  content: "\e965";
}
.ek-redo:before {
  content: "\e966";
}
.ek-bubble:before {
  content: "\e96e";
}
.ek-user:before {
  content: "\e971";
}
.ek-users:before {
  content: "\e972";
}
.ek-user-plus:before {
  content: "\e973";
}
.ek-user-minus:before {
  content: "\e974";
}
.ek-user-check:before {
  content: "\e975";
}
.ek-user-tie:before {
  content: "\e976";
}
.ek-hour-glass:before {
  content: "\e979";
}
.ek-spinner:before {
  content: "\e97b";
}
.ek-spinner11:before {
  content: "\e984";
}
.ek-binoculars:before {
  content: "\e985";
}
.ek-search:before {
  content: "\e986";
}
.ek-zoom-in:before {
  content: "\e987";
}
.ek-zoom-out:before {
  content: "\e988";
}
.ek-enlarge:before {
  content: "\e989";
}
.ek-shrink:before {
  content: "\e98a";
}
.ek-key:before {
  content: "\e98d";
}
.ek-lock:before {
  content: "\e98f";
}
.ek-unlocked:before {
  content: "\e990";
}
.ek-wrench:before {
  content: "\e991";
}
.ek-equalizer:before {
  content: "\e992";
}
.ek-cog:before {
  content: "\e994";
}
.ek-pie-chart:before {
  content: "\e99a";
}
.ek-stats-dots:before {
  content: "\e99b";
}
.ek-stats-bars:before {
  content: "\e99c";
}
.ek-bin:before {
  content: "\e9ac";
}
.ek-airplane:before {
  content: "\e9af";
}
.ek-truck:before {
  content: "\e9b0";
}
.ek-switch:before {
  content: "\e9b6";
}
.ek-clipboard:before {
  content: "\e9b8";
}
.ek-list-numbered:before {
  content: "\e9b9";
}
.ek-list:before {
  content: "\e9ba";
}
.ek-menu:before {
  content: "\e9bd";
}
.ek-cloud:before {
  content: "\e9c1";
}
.ek-cloud-download:before {
  content: "\e9c2";
}
.ek-cloud-upload:before {
  content: "\e9c3";
}
.ek-cloud-check:before {
  content: "\e9c4";
}
.ek-earth:before {
  content: "\e9ca";
}
.ek-link:before {
  content: "\e9cb";
}
.ek-attachment:before {
  content: "\e9cd";
}
.ek-eye:before {
  content: "\e9ce";
}
.ek-eye-blocked:before {
  content: "\e9d1";
}
.ek-warning:before {
  content: "\ea07";
}
.ek-notification:before {
  content: "\ea08";
}
.ek-question:before {
  content: "\ea09";
}
.ek-plus:before {
  content: "\ea0a";
}
.ek-info:before {
  content: "\ea0c";
}
.ek-cancel-circle:before {
  content: "\ea0d";
}
.ek-cross:before {
  content: "\ea0f";
}
.ek-checkmark:before {
  content: "\ea10";
}
.ek-enter:before {
  content: "\ea13";
}
.ek-exit:before {
  content: "\ea14";
}
.ek-arrow-right:before {
  content: "\ea34";
}
.ek-arrow-left:before {
  content: "\ea38";
}
.ek-sort-amount-asc:before {
  content: "\ea4c";
}
.ek-sort-amount-desc:before {
  content: "\ea4d";
}
.ek-checkbox-checked:before {
  content: "\ea52";
}
.ek-checkbox-unchecked:before {
  content: "\ea53";
}
.ek-radio-checked:before {
  content: "\ea54";
}
.ek-radio-unchecked:before {
  content: "\ea56";
}
.ek-filter:before {
  content: "\ea5b";
}
